<template>
  <v-app>
    <v-system-bar app height="40px" color="yellow" absolute>
      <v-spacer></v-spacer>
      <span>Demonstration purposes only. Do not use this information.</span>
      <v-spacer></v-spacer>
    </v-system-bar>

    <v-app-bar app color="cyan" dark absolute>
      InforMEA Akoma Ntoso XML Demo
    </v-app-bar>

    <v-main>
      <v-container>
        <div class="la-akoma-ntoso-with-gutter">
          <la-decorate-internal-refs akoma-ntoso="#doc" flag popups></la-decorate-internal-refs>
          <la-akoma-ntoso id="doc" v-html="expression.content_html" class="flash-target">
          </la-akoma-ntoso>

          <la-gutter akoma-ntoso="#doc">
            <diff-gutter-item
              v-for="[elementId, diffsets] of diffsets"
              :key="elementId"
              :elementId="elementId"
              :diffsets="diffsets"
            ></diff-gutter-item>
          </la-gutter>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import './akomantoso.scss';
import { Data, diffsetsForExpression, groupDiffsetsByElement } from '@/data/work';
import DiffGutterItem from '@/components/DiffGutterItem';

const expression = Data.latest_expression;

export default {
  name: 'App',

  components: { DiffGutterItem },

  data: () => ({
    switchToSide: false,
    dialog: false,
    expression: expression,
    diffsets: groupDiffsetsByElement(diffsetsForExpression(expression))
  })
};
</script>
