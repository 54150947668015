import data from './document.json';

// data.expressions is a list of points in time, oldest first, with full toc and HTML information
const expressions = data.expressions;

// decorate each expression
for (const expr of expressions) {
  // parse the html once
  expr.aknDoc = new DOMParser().parseFromString('<div>' + expr.content_html + '</div>', 'text/html');
  expr.aknRoot = expr.aknDoc.body.firstElementChild;
}

export const Data = {
  expressions: expressions,
  latest_expression: expressions[expressions.length - 1],
  diffsets: data.diffsets
};

/** diffsets for changes at this expression, or earlier */
export function diffsetsForExpression (expression) {
  return Data.diffsets.filter(d => d.expression_frbr_uri <= expression.expression_frbr_uri);
}

/** group diffsets by the element that changed */
export function groupDiffsetsByElement (diffsets) {
  const groups = new Map();
  for (const diffset of diffsets) {
    if (!groups.has(diffset.element_id)) {
      groups.set(diffset.element_id, []);
    }
    groups.get(diffset.element_id).push(diffset);
  }

  return groups;
}
