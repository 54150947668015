<template>
  <la-gutter-item :key="elementId" :anchor="`#${elementId}`">
    <v-card
      color="grey lighten-2"
      elevation="0"
    >
      <v-card-text class="pt-2 pl-2 pr-2 pb-2">
        This provision has been amended.

        <v-dialog max-width="900" v-model="dialog">
          <template v-slot:activator="{ on, attrs}">
            <v-btn small
                   v-bind="attrs"
                   v-on="on"
            >What changed?</v-btn>
          </template>

          <v-card>
            <diff :diffset="diffsets[0]" :switchToSide="switchToSide"></diff>

            <v-divider></v-divider>

            <v-card-actions>
              <v-switch v-model="switchToSide" label="Show changes side-by-side"></v-switch>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </la-gutter-item>
</template>

<script>
import Diff from './Diff.vue';

export default {
  name: 'DiffGutterItem',
  props: ['elementId', 'diffsets'],
  components: { Diff },
  data: () => ({
    dialog: false,
    switchToSide: false
  })
};
</script>
