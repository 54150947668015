<template>
  <div
    v-if="!switchToSide"
    class="akoma-ntoso diffset pa-3"
    v-html="diffset.html_diff"
    style="max-height: 50vh; overflow-y: auto;"
  ></div>
  <div
    v-else
    class="d-flex justify-space-between pa-3"
    style="max-height: 50vh; overflow-y: auto;"
  >
    <div
      class="akoma-ntoso diffset diffset-left"
      v-html="diffset.html_diff"
    ></div>
    <div
      class="akoma-ntoso diffset diffset-right"
      v-html="diffset.html_diff"
    ></div>
  </div>
</template>

<script>
import './diffs.scss';

export default {
  name: 'Diff',
  props: ['diffset', 'switchToSide']
};
</script>
